import React, { Component, addCallback, addReducer, useGlobal } from 'reactn'
import {
    SimpleGrid,
    Box,
    Container,
    Divider,
    useBreakpointValue,
} from '@chakra-ui/react';
import Promo from './sections/promo'
import ShoppingCartTotals from './shoppingCartTotals'
const ShoppingCartFooter = ({ globalState }) => {

    const { store, updateGlobal } = globalState
    const { cartOpen, checkoutOpen, itemsInCart, calculating, showPayment } = store

    // const [cartTotal] = useGlobal('cartTotal')
    // const [shipping] = useGlobal('shipping')
    // const [discount] = useGlobal('discount')
    // const [packaging] = useGlobal('packaging')
    // const [checkoutOpen] = useGlobal('checkoutOpen')
    // const [showPayment, setShowPayment] = useGlobal('showPayment')
    const splitView = useBreakpointValue ([false, false, true, true])
    // const updateTotals = useDispatch('updateTotals')
    // const [totals] = useGlobal('totals')

    // console.log("TOTALS", totals)

    // useEffect(() => {
    //     updateTotals
    // }, [totacarls])

    const formattedNumber = (number) => {
        return Number(number).toFixed(2);  
    }
    return (
        
        <Box id="shoppingCartBottom" mr={5} ml={5} mt={10} >     
        
        {/* { !splitView &&    */}
        {/* <> */}
        {/* XXXXUJHHK */}
            {/* <Box mb={5}>
                // <Promo globalState={globalState}/>     
            </Box> */}
            {/* </> */}
        {/* } */}
            {/* <Divider  mb={5}/> */}
            <ShoppingCartTotals globalState={globalState}/>                            
            {/* <Box height="30px"> 
            {packaging.calculated && packaging.isCircular &&
                <Box textColor={'teal'} fontSize={16} textAlign='center' mt={2}>
                    {packaging.benefit}
                </Box>
            }            
            </Box> */}
            {/* <Divider mt={5} mb={5}/> */}
            {/* {checkoutOpen  */}
            {/* && !showPayment  */}
            {/* &&  */}
                {/* <Promo globalState={globalState}/> */}
            {/* } */}
        </Box>
    )
}

export default ShoppingCartFooter