import React, { useGlobal   } from 'reactn'
import {
    SimpleGrid,
    Box,
    Container,
    Divider,
} from '@chakra-ui/react';
import { formatPrice } from '../../utils/format-price';

const ShoppingCartTotals = ({ globalState }) => {

    console.log("SCT", globalState)
    const { store } = globalState
    const { ts } = store

    // const [ts] = useGlobal('ts')
    
    // const formattedNumber = (number) => {
    //     return Number(number> 0 ? number / 100 : 0).toFixed(2);
    // }

    return (
        <Box id="CartTotals">
            <Divider  marginBottom={2} />
        <SimpleGrid columns={2}>
            {/* <Box /> */}
            <Container ml={0} mt={0} p={0} fontSize={13}>SHIPPING</Container>
            <Box pr={4} mt={1} fontSize={13} textAlign={'right'}>
                {formatPrice(ts.shipping_price)}
            </Box>
        </SimpleGrid>
        {ts.discount_price &&
            <SimpleGrid columns={2}>
                {/* <Box /> */}
                <Container ml={0} mt={0} p={0} fontSize={13}>DISCOUNT</Container>


                <Box pr={4} mt={0} fontSize={13} textAlign={'right'}>
                    {formatPrice(ts.discount_price)}
                </Box>

            </SimpleGrid>
            
        }
        <SimpleGrid columns={2}>
            {/* <Box /> */}
            <Container ml={0} mt={0} p={0} fontSize={13}>TOTAL</Container>

            <Box pr={4} mt={0} fontSize={13} textAlign={'right'}>
                 {/* {formattedNumber(cartTotal.totalPrice + shipping.value + packaging.value - discount.value)} */}
                 {formatPrice(ts.total_price)}
            </Box>
        </SimpleGrid>
        </Box>
    )
}

export default ShoppingCartTotals