const Table = {
  parts: ["table", "th","td", "caption"],
  baseStyle: {    
    table: {
      width: "100%",
      fontVariantNumeric: "lining-nums tabular-nums",
      borderCollapse: "collapse",
    },
    th: {
      fontFamily: "ApercuLight",
      color: 'brandWhite.900',
      fontWeight: "bold",
      textTransform: "uppercase",
      letterSpacing: "wider",
      textAlign: "start",
    },
    td: {
      color: 'brandWhite.600',
      textAlign: "start",
    },
    caption: {
      mt: 4,
      color: 'brandWhite.900',
      fontFamily: "heading",
      textAlign: "center",
      fontWeight: "medium",
    },
  },
  variants: {    
  }
}

export default Table

