import React from "react"
import PropTypes from "prop-types"
import { Box, AccordionButton as ChakraAccordianButton } from "@chakra-ui/react"
import { AddIcon, MinusIcon } from "@chakra-ui/icons";


const AccordionButton = ({ title,  isExpanded }) => {
    return (
        <ChakraAccordianButton>
            <Box flex="1" >
                {title}
            </Box>
            {isExpanded ? (
                <MinusIcon fontSize="8px" />
            ) : (
                <AddIcon fontSize="8px" />
            )}
        </ChakraAccordianButton>
    )
}

AccordionButton.propTypes = {
    title: PropTypes.string.isRequired,

}

AccordionButton.defaultProps = {
    data: [],
}

export default AccordionButton

