import { Box } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import React, {setGlobal} from "reactn"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Image } from "@chakra-ui/react"

function SearchLogo({isWhite = true}) {
  const { 
    image,
    imageblack
   } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "searchLogo.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 20
              quality: 90
              placeholder: NONE
              layout: FIXED
              formats: [AUTO, WEBP, AVIF]          
            )
          }
        },
        imageblack: file(relativePath: { eq: "searchLogoGrey.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 20
              quality: 90
              placeholder: NONE
              layout: FIXED
              formats: [AUTO, WEBP, AVIF]          
            )
          }
        }
      }
    `
  )
  
  const  imgData = isWhite ? getImage(imageblack).images.fallback : getImage(image).images.fallback
  return (
    <Box id="searchLogo" height={'100%'} width={'40px'}   visibility='visible' onClick={() => setGlobal({searchVisible: true})}>
            <Image ml={'10px'} mt={1} height={'20px'} width={'20px'} loading='eager' srcSet={imgData.srcSet} title="INGMARSON Search Icon" alt="INGMARSON Search Icon"   />            
    </Box>
  )
}

export default SearchLogo
