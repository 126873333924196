import React, { useDispatch } from 'reactn'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, DrawerCloseButton } from '@chakra-ui/modal';
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js';
// import { push as Menu } from '../../plugins/react-burger-menu/lib/BurgerMenu'
// import { isMobile } from 'react-device-detect'
import { Center, Flex, Grid, GridItem, Spacer, useBreakpointValue, VStack } from "@chakra-ui/react"
import { navigate } from 'gatsby';
// import { useLocation } from "@reach/router"
// import { SizeMe } from 'react-sizeme'
import ShoppingCart from './shoppingCart'
import { Spinner } from '@chakra-ui/react'
import CartError from './cartError'
import Checkout from './checkout'
// import Checkout1 from './checkout1'
import { Box, Menu, SimpleGrid, Heading, Button } from '@chakra-ui/react'
import './cartSidebar.css'
import { ArrowBackIcon, ArrowForwardIcon, NotAllowedIcon } from '@chakra-ui/icons';
import PaymentForm from './cartPayment';
import { CartButton } from '../cart-button';
import ShoppingCartFooter from './shoppingCartFooter';
import ViewHeightComponent from '../viewHeightComponent'
import { Dots } from './dotsAnimation';
import Promo from './sections/promo';
import { useOnlineStatus } from '../onlineStatus';
// class CartSidebar extends React.Component {
export function CartSidebar({ location, globalState }) {
  // const location = useLocation()
  const { store, updateGlobal } = globalState
  const { cartOpen, checkoutOpen, itemsInCart, calculating, processing } = store
  // const [cartOpen] = useGlobal('cartOpen')

  // const [cartTotal] = useGlobal('cartTotal');
  // const [showPayment] = useGlobal('showPayment');
  // const clearShipping = useDispatch('clearShipping')
  // const [checkoutOpen] = useGlobal('checkoutOpen')
  // const [itemsInCart] = useGlobal('itemsInCart')
  // const [checkoutOpen] = useGlobal('checkoutOpen')
  // const [stripe] = useGlobal('stripe')
  const fetchCart = useDispatch('fetchCart')
  const isOnline = useOnlineStatus();
  // const [calculating] = useGlobal('calculating')
  // const [cartProducts] = useGlobal('cartProducts')
  // const [clientSecret, setClientSecret] = useGlobal('clientSecret');
  // const [shipping] = useGlobal('shipping')
  // const [stripeOptions, setStripeOptions] = useGlobal('shipping')
  // const [size, setSize] = useState('md')
  // const [callingCheckout, setCallingCheckout] = useState(false)
  // const [preparePayment, setPreparePayment] = useGlobal('preparePayment')

  // const stripeOptions = {
  //   clientSecret: clientSecret,
  //   appearance: appearance
  // };


  React.useEffect(() => {
    // alert(JSON.stringify(globalState))
    // alert(cartOpen)
    if (cartOpen == true ) {
      // alert("XXX1")
      fetchCart()
    }
  }, [cartOpen])

  // const location = useLocation()
  // function callCheckout() {    
  //   setCallingCheckout(true)
  //   if (cartProducts.length === 0) {
  //     alert("NO ITEMS IN CART")
  //     return;
  //   }
  //   console.log("CART PRODUCTS", cartProducts)
  //   console.log("LOCATION",location)
  //   const items = cartProducts.map(item => (
  //     {
  //       name: item.title,
  //       id: item.sku.replace('gid://shopify/ProductVariant/', ''),
  //       sku: item.sku,
  //       productId: item.productId.replace('gid://shopify/Product/', ''),
  //       price: item.price,
  //       quantity: item.quantity,
  //       image: item.image.images.fallback.src,
  //       currency: 'GBP',
  //       product_data: {
  //         metadata: {
  //           size: item.size
  //         }
  //       }          
  //     }
  //   ))
  //   console.log("CALL SESSION", items)
  //   window
  //     .fetch("https://staging.api.ingmarson.com/checkout/session", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({
  //         items: items,
  //         cancel_url: location.href
  //         // shipping: shipping.name,
  //         // state: shipping.value
  //       })
  //     })
  //     .then(res => {
  //       console.log("RETURNED", res.json)
  //       return res.json();
  //     })
  //     .then(data => {
  //       console.log("RETURNED DATA", data)
  //       navigate(data.sessionId.url)
  //       // setClientSecret(data.clientSecret);

  //     });

  // }
  // console.log("STRIPE", stripe)
  // useEffect(() => {
  //   if (cartProducts.length === 0) {
  //     return;
  //   }

  // }, []);
  const splitView = useBreakpointValue([false, false, true, true])
  // const splitView = useBreakpointValue([false, false, false, false])
  const size = useBreakpointValue(['full', 'full', 'md', 'md'])
  const sizeOpen = useBreakpointValue(['full', 'full', 'full', 'full'])
  const pageSize = useBreakpointValue([80, 80,90,90]) 
  // const contentWidth = useBreakpointValue(['unset', 'unset', '75%', '75%'])
  // const size = full
  // checkoutOpen || size.width < 768 ? 'full' : 'xl'
  // alert(clientSecret)
  return (
    <>
      <Drawer placement={"right"} onClose={() => updateGlobal({ cartOpen: false, checkoutOpen: false })} isOpen={cartOpen} size={checkoutOpen ? sizeOpen : size}>
        <DrawerOverlay />
        <DrawerContent
          backgroundColor={'white'}
        // w={checkoutOpen ? contentWidth : size} 
        // maxW={checkoutOpen ? contentWidth : size}
        >
          <DrawerHeader backgroundColor={'black'} pt={1} minHeight={'56px'}>
            <DrawerCloseButton size={'lg'} mt={0} _focus={{
              boxShadow: 'none'
            }}
              color={'white'} border={'none'} outline={'none'} />
            <Flex>
              <Box color={'white'} mt={2} fontSize={20} fontWeight={400}>
                CHECKOUT
              </Box>
              <Spacer />
              <Box mt={1}mr={4}>
                {/* {checkoutOpen && (!splitView || showPayment) && */}
                {checkoutOpen &&
                  <CartButton globalState={globalState} />
                }
              </Box>
            </Flex>
          </DrawerHeader>


          <DrawerBody fontSize={18} mt={1} ml={3} maxH={'90dvh'}>
            <Menu
              // noOverlay
              isOpen={cartOpen}
              // onStateChange={(state) => this.handleStateChange(state)}
              id={'cartsidebar'}
              // customBurgerIcon={<img src="/images/basket.png" />}
              // menuClassName={'cart-menu'}
              itemListClassName={'cart-list'}
              // burgerButtonClassName={'bm-burger-button-cart'}
              right
              // disableAutoFocus
              // width={'50%'}
              // width={
              //   checkoutOpen || size.width < 768 ? '100%' : '50%'
              // }
              pageWrapId={'page-wrap-cart'}
              outerContainerId={'outer-container-cart'}
            >
              {/* <ViewHeightComponent viewHeight={100 - 56}> */}
                <Box id="page-content">
                  <Grid id='grid' templateColumns={`repeat(${checkoutOpen ? splitView ? 5 : 1 : 1}, 1fr)`} gap={splitView ? '20px' : '0px'}>
                    {/* <SimpleGrid minChildWidth="120px" spacing="40px"> */}
                    
                    {(!checkoutOpen || (checkoutOpen && splitView)) &&
                      <GridItem maxW='85vw' id='gi1' colSpan={splitView ? 2 : 1}>
                        {itemsInCart ? (
                          <ViewHeightComponent >
                          <Box id="shoppingCartx" >
                            {/* <Container maxW={'100%'} width={'100%'} id="pageHeaderText" variant={'pageHeading'}>CART</Container> */}
                            {/* <ViewHeightComponent viewHeight={pageSize}> */}
                              <Flex direction={'column'} height={'88vh'} >
                                <Box flex='1' overflow={['unset','unset','scroll','scroll']}>
                                  <ShoppingCart showSummary={checkoutOpen} globalState={globalState} />
                                </Box>
                                {!checkoutOpen &&
                                  <Promo globalState={globalState} />
                                }
                                <Box>
                                  {checkoutOpen ?
                                    <ShoppingCartFooter globalState={globalState} />
                                    :
                                    <>
                                      <CartError />
                                      <Button
                                        mb={[5,5,0,0]}
                                        type={'button'}
                                        variant={'checkout'}
                                        // onClick={(event) => handleCheckoutSubmit(event)}
                                        isDisabled={!isOnline || processing || calculating}
                                        // onClick={handleSubmit}
                                        // rightIcon={calculating ? <Dots /> : <ArrowForwardIcon />}
                                        // disabled={calculating}
                                        rightIcon={calculating ? <Dots mt={2} size='xs' /> : isOnline ? <ArrowForwardIcon/> : <NotAllowedIcon/>}
                                        onClick={() => {
                                          // clearShipping() && 
                                          updateGlobal({ checkoutOpen: true })
                                          // callCheckout()
                                        }}>
                                        {calculating ? "" : "CHECKOUT"}
                                      </Button>
                                    </>
                                  }
                                </Box>
                              </Flex>
                            {/* </ViewHeightComponent> */}
                            {/* <Box >
                          INCLUDES FREE DELIVERY
                        </Box> */}


                          </Box>
                          </ViewHeightComponent>

                        ) : (
                          <ViewHeightComponent viewHeight={85}>
                            <Flex direction={'column'} height={"100%"}>
                              <Box textAlign={'center'} mt={20} flex='1'>
                                {/* <VStack spacing={20}> */}
                                <Box>YOUR CART IS EMPTY</Box>
                              </Box>
                              <Box>
                                <Button
                                  variant="checkout" fontSize={14}
                                  leftIcon={<ArrowBackIcon />}
                                  onClick={() => updateGlobal({ cartOpen: false })}>
                                  CONTINUE SHOPPING
                                </Button>
                                {/* </VStack> */}
                              </Box>
                            </Flex>
                          </ViewHeightComponent>
                        )}
                      </GridItem>
                    }

                    {checkoutOpen && itemsInCart &&

                      <GridItem id='gic' maxW='85vw' colSpan={splitView ? 3 : 1} >
                        <Box id="Checkout">
                          <Checkout location={location} globalState={globalState} />
                        </Box>
                      </GridItem>
                    }
                  </Grid>
                </Box>
              {/* </ViewHeightComponent> */}
            </Menu>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* // </Elements> */}

      {/* )} */}
      {/* /> */}

    </>
  )

}
// }

// export default CartSidebar
