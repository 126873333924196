const Textarea = {
  parts: ["field"],
  baseStyle: {
    padding: '10px !important',
    'borderColor': 'black',
    'borderRadius': 'unset !important',
    'borderWidth': '3px',
    _focus: {
      'boxShadow': 'none !important',
      'borderColor': 'black !important'
    }
  }
}

export default Textarea