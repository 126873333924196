exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-alumni-products-shopify-product-tags-shopify-product-gender-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/alumniProducts/{ShopifyProduct.tags}/{ShopifyProduct.gender}/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-alumni-products-shopify-product-tags-shopify-product-gender-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-collection-shopify-collection-handle-jsx": () => import("./../../../src/pages/collection/{ShopifyCollection.handle}.jsx" /* webpackChunkName: "component---src-pages-collection-shopify-collection-handle-jsx" */),
  "component---src-pages-dato-cms-singlepage-slug-jsx": () => import("./../../../src/pages/{DatoCmsSinglepage.slug}.jsx" /* webpackChunkName: "component---src-pages-dato-cms-singlepage-slug-jsx" */),
  "component---src-pages-non-alumni-products-shopify-product-tags-shopify-product-gender-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/nonAlumniProducts/{ShopifyProduct.tags}/{ShopifyProduct.gender}/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-non-alumni-products-shopify-product-tags-shopify-product-gender-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-products-shopify-collection-handle-jsx": () => import("./../../../src/pages/products/{ShopifyCollection.handle}.jsx" /* webpackChunkName: "component---src-pages-products-shopify-collection-handle-jsx" */),
  "component---src-pages-sale-shopify-product-handle-jsx": () => import("./../../../src/pages/sale/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-sale-shopify-product-handle-jsx" */),
  "component---src-pages-stories-dato-cms-story-slug-jsx": () => import("./../../../src/pages/stories/{DatoCmsStory.slug}.jsx" /* webpackChunkName: "component---src-pages-stories-dato-cms-story-slug-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-voucher-shopify-collection-handle-jsx": () => import("./../../../src/pages/voucher/{ShopifyCollection.handle}.jsx" /* webpackChunkName: "component---src-pages-voucher-shopify-collection-handle-jsx" */),
  "component---src-pages-voucher-shopify-product-handle-jsx": () => import("./../../../src/pages/voucher/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-voucher-shopify-product-handle-jsx" */)
}

