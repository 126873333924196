
const Checkbox = {
  baseStyle: {
    icon: {
      color: 'black',
    },
    control: {
      // fontSize: '12px',
      border: '1px',
      borderColor: 'gray.300',
      bg: 'white',
      borderRadius: '0',
      _disabled: {
        borderColor: 'gray.300',
        bg: 'gray.200',
      },
      _hover: {
        borderColor: 'gray.300',
        bg: 'gray.100',
      },
      _checked: {
        _hover: {
          borderColor: 'gray.300',
          bg: 'gray.100',
        },
        borderColor: 'gray.300',
        bg: 'white',
      }
    },
    label: {
      fontSize: '10px !important',
      fontWeight: '500',
      color: '#595959',
    },
  },
}

export default Checkbox