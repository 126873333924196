import React from "react"
import PropTypes from "prop-types"
import { AccordionPanel, AccordionItem as ChakraAccordianItem } from "@chakra-ui/react"
import AccordionButton from './accordianButton'

const AccordionItem = ({ title, children, attribute, data, disabled }) => {
    return (
        <ChakraAccordianItem isDisabled={disabled}>
        {({ isExpanded }) => (
            <>
          <AccordionButton title={title} attribute={attribute} data={data} isExpanded={isExpanded} isD/>
          <AccordionPanel paddingBottom={0}>
              {children}
          </AccordionPanel>
          </>
        )}
        </ChakraAccordianItem>
    )
}

export default AccordionItem

AccordionItem.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
}

AccordionItem.defaultProps = {
    disabled: false
  }

