export default `
@font-face {
    font-family: 'ApercuLight';
    src: url('/fonts/apercu-light.eot');
    src: url('/fonts/apercu-light.eot?#iefix') format('embedded-opentype'),
         url('/fonts/apercu-light.svg#ApercuLight') format('svg'),
     url('/fonts/apercu-light.woff') format('woff'),
     url('/fonts/apercu-light.woff2') format('woff2'),
         url('/fonts/apercu-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Aktifo-A';
    src: url('/fonts/Aktifo-A-Bold.eot');
    src: url('/fonts/Aktifo-A-Bold.eot?#iefix') format('embedded-opentype'),
         url('/fonts/Aktifo-A-Bold.svg#ApercuLight') format('svg'),
     url('/fonts/Aktifo-A-Bold.woff') format('woff'),
     url('/fonts/Aktifo-A-Bold.woff2') format('woff2');
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-display: swap;
  }
`

  