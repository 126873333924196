const Accordion = {
  parts: ["container", "button", "panel", "icon"],
  baseStyle: {
    container: {
      _last: {
        borderBottomWidth: '0px'
        },      
      borderTopWidth: '0px',
      width: "100%",
      color: "brandWhite.600",
      
    },
    panel: {
      width: "100%",
      paddingBottom: 4,
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 14,
      width: "100%",
      
    },
    button: {
      // textTransform: 'uppercase',
      width: "100%",
      borderBottomWidth: '0px',
      flex: "1",
      fontWeight: '600',
      fontSize: 14,
      textAlign: "left",
      color: "brandWhite.700",
      paddingLeft: 0,
      paddingRight: 0,
      borderBottom: '1px',
      borderColor: 'gray.200',
      _focus: {
        outline: 0,
        boxShadow: 'none'
      },
      _hover: {
        fontWeight: '900',
        color: "brandWhite.900",
        bg: 'none',
      },  
    }
  },
  variants: {
  }
}

export default Accordion
