const Input = {
  parts: ["field"],
  baseStyle: {        
    field: {
      'borderColor': 'black',
      'borderRadius': 'unset !important',
      'borderWidth': '3px',
      _focus: {
        'boxShadow': 'none !important',
        'borderColor': 'black !important'
      }
    }    
  },
  variants: {
    "promocode": {
      field: {
        marginLeft: '0px !important',
        marginRight: '0px  !important'        
      }
    },
    "searchbox": {
      field: {
        'borderWidth': '1px',
        color: 'brandWhite.500',    
        backgroundColor: 'white',
        fontSize: '14px',
        fontWeight: "500",
        'borderColor': 'brandWhite.100',
        'boxShadow': 'none !important',
        _focus: {       
          'borderColor': 'brandWhite.100'
        }
      }
    },
    "desigInput": {
      field: {
        fontFamily: 'ApercuLight',
        textTransform: 'uppercase',
        'borderWidth': '1px',
        color: 'brandWhite.900',    
        backgroundColor: 'white',
        fontSize: ['12px','12px','14px','14px'],
        fontWeight: "500",
        'borderColor': 'brandWhite.100',
        'boxShadow': 'none !important',
        _focus: {       
          'borderColor': 'brandWhite.100'
        }
      }
    }
  }
    
}

export default Input