import React, { addReducer, useState, useDispatch } from 'reactn'
import { graphql } from "gatsby"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import SecureLS from "secure-ls";
import { Global } from '@emotion/react'
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import myTheme from "../styles/theme"
import fonts from "../styles/fonts"
import { LeftDrawer } from '../components/drawer'
import { Header } from '../components/header'
import { Helmet } from 'react-helmet';
import { setUser } from '../components/auth/auth'
import Logging from '../components/logging'
import { Provider, ErrorBoundary } from '@rollbar/react'
import sha256 from 'crypto-js/sha256';

import { cartOpened, itemAdded, itemRemoved, itemUpdated, orderCompleted } from './analytics'

const rollbarConfig = {
  accessToken: '6e49de8e4020450ea34e7b024598d184',
  environment: 'testenv'
};

function createContentDigest(content) {
  return sha256(content)
}

let cartStateHash = ""

import { clearShipping, completePaypalPayment, fetchCartData, fetchCartState, fetchShippingRates, networkSetPackagingOption, networkSetShipping, orderData } from './network';
import { createContext, useContext } from 'react';
import { init } from 'rollbar';
import { OnlineStatusProvider } from '../components/onlineStatus';
// import debounceEffect from '../components/debounceEffect';


// import Client from "shopify-buy" 
// import GlobalHeader from '../components/globalHeader'
// import { CartSidebar } from "../components/CheckoutPage/cartSidebar"
// import GlobalHeader from '../components/globalHeader'

// const defaultState = {
//   design: {},
//   scarfOrder: {},
//   links: [],
//   scarves: {},
//   scarfID: '',
// }


const theme = extendTheme(myTheme)
// const klarnaEndpoint = process.env.GATSBY_KLARNA_ENDPOINT
// const klarnaKey = process.env.GATSBY_KLARNA_KEY
// const gtag = process.env.GATSBY_GTAG_KEY
// var gtagURL = "https://www.googletagmanager.com/gtag/js?id=" + gtag


var ls;
var cartData;

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

export const cartEmpty = {
  cartOpen: false,
  cartUpdating: false,
  checkoutOpen: false,
  promoCode: {
    promo_code: "",
    applied: false,
    validCode: true,
    validated: true
  },
  differentBillingAddress: false,
  itemsInCart: false,
  stripeOptions: false,
  cartState: {
    sectionOpen: 1
  },
  addressRequired: true,
  shippingError: "",
  preparePayment: false,
  accordianItem: 0,
  shipping: {
    calculated: false,
    name: "",
    value: 0
  },
  discount: {
    calculated: false,
    name: "",
    value: 0
  },
  calculating: false,
  cartError: "",
  promoCodeApplied: false,
  packaging: {
    calculated: false,
    name: "",
    value: 0
  },
  packagingOptions: [],
  rates: [],
  shippingName: "",
  shippingID: "",
  packagingID: "",
  packagingName: "",
  shipping_address: {
    address1: "",
    address2: "",
    city: "",
    locality: "",
    zip: "",
    country_code: "GB",
  },
  billing_address: {},
  cartProducts: [],
  payPalOrderID: "",
  // paymentAmount: 0,
  orderData: {},
  // cartTotal: {
  //   productQuantity: 0,
  //   totalPrice: 0,
  //   currencyId: 'GBP',
  //   currencyFormat: '£'
  // },
  // totals: {
  //   shipping: 0,
  //   discount: 0,
  //   items: 0,
  //   total: 0
  // }
}

export const defaultValues = {
  storeInitilised: true,
  scarfOrder: {},
  links: [],
  scarves: {},
  scarfID: '',
  cart: [],
  cartUpdating: false,
  isOpen: false,
  loading: false,
  onOpen: () => { },
  onClose: () => { },
  addVariantToCart: () => { },
  removeLineItem: () => { },
  updateLineItem: () => { },
  client,
  checkout: {
    lineItems: [],
  },
  ss: {},
  pd: {},
  ts: {
    pc: false,
  },
  cartInitialised: false,
  runUpdateTotals: false,
  marketing: false,
  accepts_marketing: false,
  contactNumber: "",
  emailAddress: "",
  fullName: "",
  packagingID: "",
  shippingID: "",
  marketing: true
}

function getStore() {

  // LOAD CART

  // setGlobal({ 
  //   client: client,
  //   loading: loading,
  //   showPayment: false,
  //   sidebarOpen: false 

  // })    
  // alert("GET STORE")
  var initialState = {}
  // var cartData = localStorage.getItem('ingmarson_cart');
  if (typeof window !== 'undefined' && localStorage) {
    ls = new SecureLS({});
    cartData = ls.get('ingmarson_cart');
    cartStateHash = cartData
  }
  if (cartData) {

    var cartJSON = JSON.parse(cartData)
    console.log("X1 - CART STATE EXISTS - LOADING", cartJSON, window.location)
    initialState = {
      scarfOrder: cartJSON.scarfOrder ? cartJSON.scarfOrder : {},
      links: cartJSON.links ? cartJSON.links : [],
      scarves: cartJSON.scarves ? cartJSON.scarves : {},
      scarfID: cartJSON.scarfID ? cartJSON.scarfID : '',
      storeInitilised: true,
      sid: cartJSON.sid,
      cartAvailable: true,
      cartInitialised: false,
      cartOpen: false,
      checkoutOpen: false,
      runUpdateTotals: false,
      promoCode: cartJSON.promoCode && cartJSON.promoCode.promo_code ? cartJSON.promoCode : {
        promo_code: "",
        applied: false,
        validCode: true,
        validated: true
      },
      ss: cartJSON.ss,
      pd: {},
      ts: {
        pc: false,
      },
      differentBillingAddress: cartJSON.differentBillingAddress,
      cartState: cartJSON.cartState,
      emailAddress: cartJSON.emailAddress,
      contactNumber: cartJSON.contactNumber,
      shipping_address: cartJSON.shipping_address,
      fullName: cartJSON.fullName,
      shippingID: cartJSON.shippingID,
      packagingID: cartJSON.packagingID,
      marketing: false,
      billing_address: cartJSON.billing_address,
      showShipping: false,
      payPalOrderID: "",
      preparePayment: false,
      stripeOptions: false,
      shippingError: "",
      accepts_marketing: false,
      shipping: {
        calculated: false,
        name: "",
        value: 0
      },
      addressRequired: true,
      cartError: "",
      calculating: false,
      accordianItem: 0,
      discount: {
        calculated: false,
        name: "",
        value: 0
      },
      packaging: {
        calculated: false,
        name: "",
        value: 0
      },
      packagingOptions: [],
      rates: [],
      paymentAmount: cartJSON.paymentAmount,
      orderData: {},
      shippingName: "",
      packagingName: "",
      shippingID: "",
      packagingID: "",
      itemsInCart: cartJSON.itemsInCart,
      cartProducts: cartJSON.cartProducts ? cartJSON.cartProducts : [],
      cartTotal: cartJSON.cartTotal ? cartJSON.cartTotal : {
        productQuantity: 0,
        totalPrice: 0,
        currencyId: 'GBP',
        currencyFormat: '£'
      },
      totals: cartJSON.totals ? cartJSON.totals : {
        shipping: 0,
        discount: 0,
        items: 0,
        total: 0
      },
    }
    // setStore(initialState)
  } else {
    // setStore(cartEmpty)
    console.log("X1 SET STORE - DOES NOT EXIST")
    initialState = { ...defaultValues, ...cartEmpty }
    // setStore(initialState)
  }

  // (async () => {
  //   try {
  //     const stripe = await loadStripe(process.env.GATSBY_STRIPE_API_KEY);
  //     console.log("X1 - LOADED STRIPE", stripe)
  //     initialState = (...{ stripe: stripe }, initialState)


  //     stripe.createRadarSession().then(function (result) {
  //       console.log("RADAR", result)
  //       if (result.error) {
  //         console.log("RADAR", result.error)
  //       } else {
  //         if (result.radarSession) {
  //           updateAndSave ({ stripe: stripe,
  //             radarID: result.radarSession.id }, initialState)
  //         }
  //       }
  //     });
  //   } catch (e) {
  //     console.log("FAILED TO LOAD STRIPE")
  //   }
  // })()
  // alert("STOORE DONE")
  console.log("X1 - RETURNING INITIAL STATE", initialState)
  return initialState
  // setGlobal({ cartInitialised: true })
}

export const GlobalContext = createContext(getStore());





export const SiteContext = ({ children }) => {

  const [store, setStore] = useState(useContext(GlobalContext));

  const updateGlobal = async (objectToUpdate) => {
    console.log("X1 - UPDATE GLOBAL", objectToUpdate)
    updateAndSave(objectToUpdate, store)
    // const newStore = { ...store, ...objectToUpdate }
    // setStore(newStore)
    // saveState(newStore)
  };

  function emptyCart(currentStore) {
    const newStore = currentStore ? { ...currentStore, ...cartEmpty } : { ...store, ...cartEmpty }
    console.log("X1 SET STORE - EMPTY CART", newStore)
    setStore(newStore)
    saveState(newStore)
    return newStore
  }

  const updateAndSave = async (newData, currentStore) => {
    console.log("X1 - UPDATE AND SAVE", currentStore, newData)
    const newStore = currentStore ? { ...currentStore, ...newData } : { ...store, ...newData }
    console.log("X1 - UPDATE AND SAVE", newStore)
    setStore(newStore)
    saveState(newStore)

  }

  async function saveState(processingState) {
    console.log("CALL SAVED STATE", processingState)
    const stateString = JSON.stringify(processingState)
    const cartHash = createContentDigest(stateString)
    console.log("SETTING CART DATA CART HASH", cartHash)
    if (cartHash != cartStateHash) {
      cartStateHash = cartHash
      ls.set('ingmarson_cart', stateString)
      console.log("SETTING CART DATA")
    } else {
      console.log("*********** NOT SETTING CART DATA")
    }
    console.log("CALL SAVED STATE END")
  }



  // const [checkout, setCheckout] = useState(defaultValues.checkout)
  // const [loading, setLoading] = useState(false)
  // const [cartInitialised] = useState(false)
  // const [ip] = useState(null)
  // const [runUpdateTotals] = useGlobal('runUpdateTotals')
  // const [promoCode] = useState("")
  // const [sid] = useState(null)
  // const [cartTotal] = useGlobal('cartTotal')
  // const debouncedCartTotal = debounceEffect(cartTotal, 600)

  // const [globalContext] = useGlobal()

  // if (typeof window !== 'undefined' && localStorage) {
  //   ls = new SecureLS({});
  //   cartData = ls.get('ingmarson_cart');
  //   cartStateHash = cartData
  // }


  // React.useEffect(() => {
  //   if (runUpdateTotals == true) {
  //     // alert("RUNNING UPDATE TOTALS")
  //     setGlobal({ runUpdateTotals: false })
  //     runUpdateTotal()
  //     // console.log("NEW TOTALS", newTotals)
  //     // setGlobal({totals: newTotals})
  //   }
  // }, [runUpdateTotals])

  React.useEffect(() => {
    if (store.cartInitialised != undefined && store.cartInitialised == true) {
      if (store.cartOpen == true) {
        cartOpened(store)
      } else {
        console.log("CART CLOSED", store)
      }
    }
  }, [store.cartOpen])

  React.useEffect(() => {

    console.log("X2 - RE FETCH SHIPPING")
    if (store.cartInitialised != undefined && store.cartInitialised == true) {
      // alert("X1")
      fetchCart(false)
    }
  }, [store.shippingID])

  React.useEffect(() => {

    console.log("X2 - FETCH PROMO PR SHIPPING")
    if (store.cartInitialised != undefined && store.cartInitialised == true) {
      // alert("X2")
      fetchCart(true)
    }
  }, [store.promoCode.promo_code])

  React.useEffect(() => {
    if (store.sid == undefined || null) {
      setUser("")
    } else {
      setUser(sid)
    }

  }, [store.sid])


  //   React.useEffect(() => { 
  //     if (cartInitialised == true) {
  //       fetchCart()
  //     }
  // }, [cartTotal])


  React.useEffect(() => {
    console.log("XXXXXX11", store.ip, store.cartInitialised)
    if (store.ip != null && store.cartInitialised == undefined || false) {
      console.log("XXXXXX11 FETCH")
      // alert("X3")
      fetchCart(true)
    }
  }, [store.cartInitialised, store.ip])

  // React.useEffect(() => {

  // //   // LOAD CART

  // //   // setGlobal({ 
  // //   //   client: client,
  // //   //   loading: loading,
  // //   //   showPayment: false,
  // //   //   sidebarOpen: false 

  // //   // })    

  // //   var initialState = {}
  // //   // var cartData = localStorage.getItem('ingmarson_cart');
  // //   if (cartData) {

  // //     var cartJSON = JSON.parse(cartData)
  // //     console.log("X1 - CART STATE EXISTS - LOADING", cartJSON)
  // //     initialState = {
  // //       storeInitilised: true,
  // //       sid: cartJSON.sid,
  // //       cartInitialised: false,
  // //       cartOpen: false,
  // //       checkoutOpen: false,
  // //       runUpdateTotals: false,
  // //       promoCode: cartJSON.promoCode && cartJSON.promoCode.promo_code ? cartJSON.promoCode : {
  // //         promo_code: "",
  // //         applied: false,
  // //         validCode: true,
  // //         validated: true
  // //       },
  // //       ss: cartJSON.ss,
  // //       pd: {},
  // //       ts: {
  // //         pc: false,
  // //       },
  // //       differentBillingAddress: cartJSON.differentBillingAddress,
  // //       cartState: cartJSON.cartState,
  // //       emailAddress: cartJSON.emailAddress,
  // //       contactNumber: cartJSON.contactNumber,
  // //       shipping_address: cartJSON.shipping_address,
  // //       fullName: cartJSON.fullName,
  // //       shippingID: cartJSON.shippingID,
  // //       packagingID: cartJSON.packagingID,
  // //       marketing: false,
  // //       billing_address: cartJSON.billing_address,
  // //       showShipping: false,
  // //       payPalOrderID: "",
  // //       preparePayment: false,
  // //       stripeOptions: false,
  // //       shippingError: "",
  // //       accepts_marketing: false,
  // //       shipping: {
  // //         calculated: false,
  // //         name: "",
  // //         value: 0
  // //       },
  // //       addressRequired: true,
  // //       cartError: "",
  // //       calculating: false,
  // //       accordianItem: 0,
  // //       discount: {
  // //         calculated: false,
  // //         name: "",
  // //         value: 0
  // //       },
  // //       packaging: {
  // //         calculated: false,
  // //         name: "",
  // //         value: 0
  // //       },
  // //       packagingOptions: [],
  // //       rates: [],
  // //       paymentAmount: cartJSON.paymentAmount,
  // //       orderData: {},
  // //       shippingName: "",
  // //       packagingName: "",
  // //       shippingID: "",
  // //       packagingID: "",
  // //       itemsInCart: cartJSON.itemsInCart,
  // //       cartProducts: cartJSON.cartProducts ? cartJSON.cartProducts : [],
  // //       cartTotal: cartJSON.cartTotal ? cartJSON.cartTotal : {
  // //         productQuantity: 0,
  // //         totalPrice: 0,
  // //         currencyId: 'GBP',
  // //         currencyFormat: '£'
  // //       },
  // //       totals: cartJSON.totals ? cartJSON.totals : {
  // //         shipping: 0,
  // //         discount: 0,
  // //         items: 0,
  // //         total: 0
  // //       },
  // //     }
  // //     setStore(initialState)
  // //   } else {
  // //     // setStore(cartEmpty)
  // //     console.log("X1 SET STORE - DOES NOT EXIST")
  // //     initialState = {...defaultValues, ...cartEmpty}
  // //     setStore(initialState)
  // //   }

  //   (async () => {
  //     try {
  //       const stripe = await loadStripe(process.env.GATSBY_STRIPE_API_KEY);
  //       console.log("X1 - LOADED STRIPE", stripe)
  //       updateAndSave ({ stripe: stripe })


  //       stripe.createRadarSession().then(function (result) {
  //         console.log("RADAR", result)
  //         if (result.error) {
  //           console.log("RADAR", result.error)
  //         } else {
  //           if (result.radarSession) {
  //             updateAndSave ({ stripe: stripe,
  //               radarID: result.radarSession.id })
  //           }
  //         }
  //       });
  //     } catch (e) {
  //       console.log("FAILED TO LOAD STRIPE")
  //     }
  //   })()

  //   // setGlobal({ cartInitialised: true })
  // }, [])


  addReducer('clearPaypal', (global, dispatch) => {
    updateAndSave({
      payPalOrderID: "",
      addressRequired: true
    })
  })

  addReducer('updateTotals', (global, dispatch) => {
    console.log("NEW TOTALS", totals(store))
    updateAndSave({ totals: totals(store) })
  })

  addReducer('fetchShippingRates', (global, dispatch, force) => {
    fetchShippingRates(store, force != undefined && force == true ? true : false)
  })


  addReducer('fetchCart', (global, dispatch, force) => {
    console.log("FEWTCH CART", store)
    // alert("FORCE")
    // alert(force)
    // fetchCartData(global, force != undefined && force == true ? true : false, dispatch)
    fetchCartState(store, updateAndSave)
  })

  addReducer('completePaypalPayment', (global, dispatch) => {
    completePaypalPayment(store)
  })

  // addReducer('setNextCartState', (global, dispatch) => {
  //   setGlobal({ cartState: global.cartState.sectionOpen + 1 })
  // })

  // addReducer('setPromoCodeValidated', (global, dispatch, promocode) => {
  //   // alert(JSON.stringify(promocode))
  //   updateAndSave({
  //     ...store,
  //     ...{
  //       promoCodeApplied: true,
  //       promoCode: {
  //         promo_code: promocode.promo_code,
  //         validCode: promocode.validCode,
  //         validated: true,
  //         applied: true
  //       }
  //     }
  //   })
  // })

  addReducer('setPromoCode', (global, dispatch, promocode) => {
    // alert("SETTING PROMO CODE")


    updateAndSave({
      promoCode: {
        promoCodeApplied: true,
        promo_code: promocode,
        validCode: false,
        applied: false,
        validated: false
      }
    })
    // setGlobal({
    //   promoCode: {
    //     promoCodeApplied: true,
    //     promo_code: promocode,
    //     validCode: false,
    //     applied: false,
    //     validated: false
    //   }
    // })
  })

  addReducer('clearPromoCode', (global, dispatch) => {
    updateAndSave({ promoCode: "" })
    // setGlobal({
    //   // promoCodeApplied: false,
    //   promoCode: {
    //     promo_code: "",
    //     applied: false,
    //     validCode: true,
    //     validated: true
    //   }
    // })
  })

  addReducer('differentBilling', (global, dispatch, differentBilling) => {
    updateAndSave({ differentBillingAddress: differentBilling })
  })

  // addCallback((global) => {
  //   if (typeof window !== 'undefined' && localStorage) {
  //     const stateString = JSON.stringify(global)
  //     const cartHash = createContentDigest(stateString)
  //     // console.log("CART STATE HASH", stateString)
  //     if (cartHash != cartStateHash) {
  //       cartStateHash = cartHash
  //       ls.set('ingmarson_cart', stateString)
  //       console.log("SETTING CART DATA", global)
  //     } else {
  //       console.log("*********** NOT SETTING CART DATA", global)
  //     }
  //   }
  //   return null
  // })

  addReducer('setAddress', (global, dispatch, data) => {
    // alert("SET ADDRESS")
    console.log("XXXSET ADDRESS", data)
    var addressData = { showShipping: true }
    addressData[data.addressType] = data.address
    addressData[data.addressType].complete = true
    addressData[data.addressType].addressOpen = true
    console.log("UODATE ADDRESS DATA", addressData)
    updateAndSave({ ...addressData, ...data.otherData })

  })

  addReducer('setDiscount', (global, dispatch, discountData) => {
    console.log("ADDING Discount Cost DATA TO GLOBAL", discountData)
    if (discountData.validCode) {
      console.log("SETTING DISCOUNT")
      updateAndSave({
        discountName: discountData.name,
        discount: {
          calculated: true,
          name: discountData.name,
          value: discountData.value / 100
        }
      })

    } else {
      updateAndSave({
        discountName: "",
        discount: {
          calculated: false,
          name: "",
          value: 0
        }
      })
    }
    // console.log("SET DISCOUNT", global.discount)
    // setGlobal({ totals: totals(global) })
  })

  addReducer('setExpressShipping', (global, dispatch, shippingData) => {
    // alert("SET SHIPPING")
    // console.error("SET EXPRESS SHIPPING", shippingData)
    updateAndSave({
      cartUpdating: true,
      showPayment: false,
      shippingID: shippingData && shippingData.id ? shippingData.id : ""
    })
    console.log("SHIPPING ID", shippingData && shippingData.id ? shippingData.id : "")
  })

  addReducer('setShipping', (global, dispatch, shippingData) => {
    // alert("SET SHIPPING")
    updateAndSave({
      showPayment: false,
      shippingID: shippingData && shippingData.id ? shippingData.id : ""
    })
    console.log("SHIPPING ID", shippingData && shippingData.id ? shippingData.id : "")
  })

  addReducer('setPackagingOption', (global, dispatch, packagingData) => {
    updateAndSave({
      packagingID: packagingData && packagingData.id ? packagingData.id : ""
    })
  })

  addReducer('clearDiscount', (global, dispatch, discountData) => {
    if (store.discount.calculated != false) {
      console.log("CLEAR SHIPPINDiscountG DATA TO GLOBAL", discountData)
      updateAndSave({
        discountName: "",
        discount: {
          calculated: false,
          name: "",
          value: 0
        }
      })
      // setGlobal({ totals: totals(global) })
    }
  })

  addReducer('clearShipping', (global, dispatch, shippingData) => {
    clearShipping(store)
  })

  addReducer('changeSize', (global, dispatch, changedProduct) => {
    console.log('CHANGE PRODUCT QUANTITY', changedProduct)
    var cartProducts = store.cartProducts

    const product = cartProducts.find((p) => p.sku === changedProduct.sku)
    product.sku = changedProduct.sku
    // if (product.quantity <= 0) {
    //   removeProduct(global, product)
    // }
    updateCart(cartProducts)
  })
  addReducer('changeProductQuantity', (global, dispatch, changedProduct) => {
    console.log('CHANGE PRODUCT QUANTITY', changedProduct)
    var cartProducts = store.cartProducts
    const product = cartProducts.find((p) => p.sku === changedProduct.sku)
    if (product) {
      product.quantity = changedProduct.quantity
      if (product.quantity <= 0) {
        removeProduct(store, product)
      }
      itemUpdated(product)
      updateCart(cartProducts)
    }
  })

  const removeProduct = (global, product) => {
    var cartProducts = store.cartProducts

    const index = cartProducts.findIndex((p) => p.sku === product.sku)
    if (index >= 0) {
      cartProducts.splice(index, 1)
      updateCart(cartProducts)
    }
  }

  addReducer('paymentComplete', (global, dispatch, order) => {
    if (store && store.ss && Object.keys(store.ss).length > 0) {
      alert("PAYMENT COMPLETE")
      if (order) {
        orderCompleted(order)
      }
      console.log("PaYMENT COMPLETE - CLEARING CART", order)

      const newData = {
        ss: {},
        pd: {},
        ts: {
          pc: false,
        },
        ...cartEmpty
      }
      const newStore = { ...store, ...newData }
      // console.log("PAYMENT COMPLETE", newStore)
      setStore(newStore)
      saveState(newStore)
    }
    // updateAndSave()

  })

  addReducer('removeProduct', (global, dispatch, product) => {
    itemRemoved(product)
    // const { cartProducts, updateCart } = this.props;
    removeProduct(store, product)
  })

  addReducer('addVariantToCart', (global, dispatch, data) => {
    addVariantToCart(data.variantId, data.quantity)
  })


  addReducer('addToCart', (global, dispatch, product) => {
    const cartProducts = store.cartProducts
    console.log("STORE ADD TO CART", store, product)
    let productAlreadyInCart = false

    cartProducts.forEach((cp) => {
      if (cp.sku === product.sku) {
        cp.quantity += product.quantity
        productAlreadyInCart = true
      }
    })
    if (!productAlreadyInCart) {
      itemAdded(product)
      cartProducts.push(product)
    }

    updateCart(cartProducts)


    // setStore({
    //   ...store, ...{
    //     cartOpen: true,
    //   }
    // })
  })

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)
    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const discountAmount = (global) => {
    return global.discount && global.discount.validCode ? global.discount.value : 0
  }

  const shippingCost = (global) => {
    return global.shipping && global.shipping.calculated ? global.shipping.value : 0
  }

  const packagingCost = (global) => {
    return global.packaging && global.packaging.calculated ? global.packaging.value : 0
  }

  const totalCost = (global) => {
    return 0 + global.cartTotal.totalPrice + shippingCost(global) + packagingCost(global) - discountAmount(global)
  }

  const totals = (global) => {
    return {
      shipping: shippingCost(global),
      packaging: packagingCost(global),
      discount: discountAmount(global),
      items: global.cartTotal.totalPrice,
      total: totalCost(global),
      totalFormatted: Number(totalCost).toFixed(2)
    }
  }

  const updateCart = (cartProducts) => {
    console.log("UPDATING CART xxx")
    let productQuantity = cartProducts.reduce((sum, p) => {
      sum += p.quantity
      return sum
    }, 0)
    let totalPrice = cartProducts.reduce((sum, p) => {
      sum += p.price * p.quantity * 100
      return sum
    }, 0)

    if (productQuantity == 0) {
      emptyCart(store)
    } else {
      let cartTotal = {
        productQuantity,
        totalPrice,
        currencyId: 'GBP',
        currencyFormat: '£',
      }

      updateAndSave({
        ...{
          cartOpen: true,
          showPayment: false,
          preparePayment: false,
          // showshipping: false,
          // // shippingID: "",
          // shipping: {
          //   calculated: false,
          //   name: "",
          //   value: 0
          // },
          // preparePayment: false,
          // accordianItem: store.accordianItem > 0 ? 1 : 0
          // global.showPayment ? 1 : global.accordianItem
        },
        ...{
          cartTotal: cartTotal,
          itemsInCart: productQuantity > 0,
          searchVisible: false,
          hitsRendered: false
        }

      })
    }

    // setStore(newStore)
    // saveState(newStore)
    // setGlobal({ totals: tcotals(global) })

  }

  const fetchCart = useDispatch('fetchCart')
  const runUpdateTotal = useDispatch('updateTotals')

  console.log("GLOBAL STORE", store)
  // const snipcartURL = process.env.GATSBY_ACTIVE_ENV == 'production' ? "/snipcart.js" : "/snipcartd.js"
  return (

    <GlobalContext.Provider value={{ store, updateGlobal }}>
      <OnlineStatusProvider>
        <Helmet lang="en">
          {/* <script type="text/javascript" src={"//maps.googleapis.com/maps/api/js?key=" + process.env.GATSBY_MAP_KEY + "&language=en&libraries=places"}></script> */}
          {/* <script async defer type="text/javascript" src={"https://www.paypal.com/sdk/js?currency=GBP&commit=false&client-id=" + process.env.GATSBY_PAYPAL_CLIENT_KEY} ></script> */}
          {/* <script async defer type="text/javascript" src="//l.getsitecontrol.com/pwp62y14.js"></script> */}
          {/* <link rel="preconnect" href={klarnaEndpoint} /> */}
          <script type="text/javascript" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
          {/* <script type="text/javascript" src="https://x.klarnacdn.net/kp/lib/v1/api.js" async></script> */}
          {/* <script type="text/javascript" async src={klarnaEndpoint} data-client-id={klarnaKey}></script> */}

          <meta charSet="utf-8" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>

        <ChakraProvider theme={theme}>
          <Provider config={rollbarConfig}>
            <ErrorBoundary>
              <Logging />
              <Global styles={fonts} />
              <Header store={store} updateGlobal={updateGlobal} />
              <LeftDrawer />
              {children}
            </ErrorBoundary>
          </Provider>

        </ChakraProvider>
      </OnlineStatusProvider>
    </GlobalContext.Provider>
  )
}


export const query = graphql`
  fragment SiteData on DatoCmsSite {
      id
      globalSeo {
        siteName
        titleSuffix
        twitterAccount
        facebookPageUrl
        fallbackSeo {
          title
          description
          twitterCard
          image {
            url
            gatsbyImageData
            smartTags
          }
        }
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  `

