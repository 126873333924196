import React, { Component, addCallback, addReducer } from 'reactn'
import {
  Stack,
  Flex,
  Heading,
  SimpleGrid,
  Button,
  Text,
  VStack,
  Center,
  Box,
  useBreakpointValue,
  Container,
  HStack,
} from '@chakra-ui/react';

import CartItem from './cartItem'
import Circular from '../../icons/circular'
import ShoppingCartFooter from './shoppingCartFooter';

// import './shoppingCart.css'

const ShoppingCart = ({ showSummary, globalState }) => {

  const {store, updateGlobal} = globalState
  const {cartProducts, cartTotal, shipping, packaging, totals} = store
  // const [cartProducts] = useGlobal('cartProducts')
  // const [cartTotal] = useGlobal('cartTotal')
  // const [shipping] = useGlobal('shipping')
  // const [packaging] = useGlobal('packaging')
  // const [totals] = useGlobal('totals')
  // // const [totals] = useGlobal('totals')
  // console.log("TOTALS", totals)
  // // const removeAlert = addCallback(global => {
  //   console.log('STATE CHANGED')
  //   cartProducts = this.global.cartProducts
  // })

  return (
    <Box id="shoppingCart" >
      {/* <Container maxW={'100%'} width={'100%'} id="pageHeaderText" variant={'pageHeading'}>CART</Container> */}
      {/* <Flex direction={'column'} flex='1'> */}
        <Box id="shoppingCartItems" >
          <VStack id="cartvstack" mr={5} ml={5}>
            {cartProducts &&
              cartProducts.map((cartItem, i) => (
                <Box width={'100%'} key={`ci${i}`}>
                  <CartItem product={cartItem} showSummary={showSummary} />
                </Box>
              ))}
          </VStack>
        </Box>
        {/* <Box > */}
          {/* <Promo></Promo>     */}
          {/* <ShoppingCartFooter /> */}
        {/* </Box> */}
      {/* </Flex> */}
    </Box>
  )
}


export default ShoppingCart
