import React from "reactn"
import { getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { Image } from "@chakra-ui/react"

export default function Logo() {
  const {
    image
  } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 373
              quality: 90
              placeholder: NONE
              layout: FIXED
              formats: [AUTO]
            )
          }
        }
      }
    `
  )

  const imgData = getImage(image).images.fallback
  return (
    <Image alt="INGMARSON Logo" title="INGMARSON Logo" loading="eager" mt={[3, 3, 2, 2]} srcSet={imgData.srcSet} src={imgData.src} height={['28px', '28px', '38px', '38px']} width={['150px', '150px', '200px', '200px']} />
  )
}