import React, { useState, useDispatch, addCallback, Component, setGlobal } from 'reactn'
// import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image"
import {
  Flex,
  SimpleGrid,
  HStack,
  Center,
  Box,
  Container,
  Select,
  Spacer,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import debounceEffect from '../debounceEffect';
import { formatPrice } from '../../utils/format-price';
// import ProductsText from '../productInfo/productsText';

const CartItem = ({ product, showSummary, showReceipt }) => {

  const changeProductQuantity = useDispatch('changeProductQuantity')

  const changeSize = useDispatch('changeSize')
  const removeProduct = useDispatch('removeProduct')
  const [quantity, setQuantity] = useState(product.quantity)
  const [runFetchCart, setRunFetchCart] = useState(false)
  const debouncedRunFetchCart = debounceEffect(runFetchCart, 1200)
  const [size] = useState(product.size)
  const [sku, setSku] = useState(product.sku)
  const fetchCart = useDispatch('fetchCart')
  
  React.useEffect(() => {
    setQuantity(product.quantity)
  }, [product.quantity])


  React.useEffect(() => {
    if (runFetchCart == true) {
      // alert("FETCH CART DUE TO CHANGE")      
      setRunFetchCart(false)
      changeProductQuantity(product)
      // fetchCart(true)

    }
  }, [debouncedRunFetchCart])



  function handleOnDecrease() {
    setQuantity(quantity - 1)
    product.quantity = product.quantity - 1
    setRunFetchCart(true)
    // changeProductQuantity(product)
  }

  function handleChangeSze(event) {
    const variant = product.variants[event.target.options.selectedIndex]
    setSku(variant.storefrontId)
    product.sku = variant.storefrontId
    changeSize(product)
  }

  function handleRemove() {
    removeProduct(product)
    setRunFetchCart(true)
    // setQuantity(0)
    // product.quantity = 0
    // changeProductQuantity(product)
  }

  function handleOnIncrease() {
    console.log("XXXXX1INCREASE")
    setQuantity(quantity + 1)
    product.quantity = product.quantity + 1
    setRunFetchCart(true)
    // changeProductQuantity(product)

  }
  console.log("SHOW SUMMARY", showSummary)
  return (
    <Box id="cartItem" width="100%">
          <Box >
            <Flex id="flex" width="100%">
              <Box maxWidth={['80px','80px','80px','100px']} minWidth={'50px'}>
                {showReceipt ?
                  <img fit="cover" src={product.image} />
                  :
                  <GatsbyImage
                    alt={product.title}
                    title={product.title}
                    image={product.image}
                    draggable={false}
                  />
                }
              </Box>
              <Box mt={5}  width="100%" >

                <Container variant='cartText'>

                  {product.title}
                  {(showReceipt || showSummary) &&
                    <Box >
                      <Flex >
                        <Box >{quantity}</Box>
                        <Box ml={1}> x </Box>
                        <Box ml={1}> {formatPrice(product.price)}</Box>

                        <Box mt={2} flex='1' mr={0} w={8} h={8} textAlign={'right'}>
                          <b>{formatPrice((product.price * quantity))} </b>
                          </Box>
                      </Flex>
                    </Box>
                  }

                </Container>
                
              </Box>
              {!showReceipt && !showSummary && 
                <Box mt={2} flex='1' mr={0} w={8} h={8} onClick={handleRemove} textAlign={'right'}>
                  <SmallCloseIcon ml={0}  mt={3}
                    color='brandWhite.500'
                    _hover={{ color: 'black' }}
                  />
                </Box>
              }

            </Flex>
            {!showReceipt && !showSummary && <Container ml={0} mt={3} p={0} fontSize={14}>SIZE</Container>}
            {!showReceipt && !showSummary && 
              <Select

                mb={3}
                minH={'50px'}
                onChange={handleChangeSze}
                _focus={{
                  borderColor: 'black'
                }}
                _hover={{
                  borderColor: 'colorWhite.800'
                }}
                borderColor='black'
                borderWidth='3px'
                value={sku}
              >
                {product.variants.map((v, i) => (
                  // <Box pt={5} key={`var${i}`}>
                  <option key={`var${i}`} value={v.storefrontId}>{v.title}</option>
                  // </Box>
                ))}

              </Select>
            }
            {!showReceipt && !showSummary && <Container ml={0} mt={3} p={0} fontSize={14}>QUANTITY</Container>}
            {!showReceipt && !showSummary && 

              <SimpleGrid columns={3} spacing="4px">
                <Box >
                  <Flex minH={'50px'} borderWidth='3px' spacing={5} borderColor='black' >

                    <Box
                      ml={3}
                      fontSize={28}
                      onClick={handleOnDecrease}
                      disabled={quantity === 1 ? true : false}
                      className="change-product-button change-product-button-left"
                    >
                      -
                    </Box>
                    <Spacer />
                    <Box ml={1} mr={1} mt={2}>
                      {quantity}
                    </Box>
                    <Spacer />
                    <Box
                      mr={3}
                      // mt={2}
                      fontSize={28}
                      onClick={handleOnIncrease}
                      className="change-product-button  change-product-button-right"
                    >
                      +
                    </Box>

                  </Flex>
                </Box>
                <Spacer />
                <Box pr={4} mt={5} fontSize={'14px'} textAlign={'right'}>
                  {formatPrice(product.price)}
                </Box>
                <Box className="column del_column">
                  <div className="shelf-item__del" onClick={() => removeProduct(product)} />
                </Box>
              </SimpleGrid>
            }
          </Box>
          
    </Box >
  )
}

export default CartItem
