
const Link = {
  baseStyle: {
    _focus: {
      boxShadow: "none"
    },
    _hover: {
      textDecoration: "none",
    }

  },
}

export default Link