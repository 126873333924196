
import React, { useDispatch, useState, useEffect } from 'reactn'

import './cartSidebar.css'
import { ExpressCheckoutElement, useElements } from '@stripe/react-stripe-js'


const ExpressPayment = ({ globalState, setVisibility }) => {

  const { store, updateGlobal } = globalState
  const { cartTotal, calculating, shipping, pd, ts, os, orderID, stripe, so, soe, expressUpdated, cartUpdating,
    preparePayment, showPayment, showShipping, accordianItem, addressRequired, marketing,
    accepts_marketing, shipping_address, contactNumber, fullName, emailAddress } = store

  console.log("X1 CHECKOUT", store)

  // const [visibility, setVisibility] = useState('hidden');
  const setExpressShipping = useDispatch('setExpressShipping')
  

  const elements = useElements()
  // const [cartUpdating, setCartUpdating] = useState(false)
  // const [cartUpdated, setCartUpdated] = useState(false)

  // const fetchCart = useDispatch('fetchCart')

  const [red, setRed] = useState(() => {});

  // const [red, setRed] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [addressComplete, setAddressComplete] = useState(false)
  const [formData, setFormData] = useState({})

  const expressCheckoutOptions = {
    // Specify a type per payment method
    // Defaults to 'buy' for Google and 'plain' for Apple
    buttonType: {
      googlePay: 'checkout',
      applePay: 'check-out',
      // paypal: 'checkout'
    },
    // Specify a theme per payment method
    // Default theme is based on appearance API settings
    buttonTheme: {
      applePay: 'black',
      googlePay: 'black',
      paypal: 'black'
    },
    // Height in pixels. Defaults to 44. The width is always '100%'.
    // buttonHeight: 44
  }
  const onReady = ({ availablePaymentMethods }) => {
    if (!availablePaymentMethods) {
      // No buttons will show
    } else {
      // Optional: Animate in the Element
      setVisibility('initial');
    }
  };

  const onClick = ({ resolve }) => {
    console.log("CLICKED", so)
    console.log("CLICKED", soe)
    const options = {
      emailRequired: true,
      phoneNumberRequired: true,
      shippingAddressRequired: true,
      // allowedShippingCountries: ['Gb'],
      shippingRates: soe,
    };

    resolve(options);
  };

  useEffect(() => {
    console.log("XCXX  CALCULATING CHANGED ", calculating, cartUpdating, expressUpdated)
    if (calculating == false && cartUpdating == true) {
      console.log("XCXX  CALCULATING CHANGED - SET COMPLETE")
      // setCartUpdated(true)
      updateGlobal({ cartUpdating: false, expressUpdated: true })          
      console.log("XCXX  CALCULATING CHANGED - SET COMPLETE DONE")
    }
  } , [calculating])

  useEffect(() => {
    console.log("XCXX  EXPRESS UPDATED CHANGED ", expressUpdated, red, ts)   
    if (expressUpdated == true && red != null)  {
      const updatePrice = async () => {
        const newPrice = Number(ts.total_price_pence)
        console.log("XCXX  EXPRESS UPDATED PRICE", newPrice)
        await elements.update({
          // amount: 50000,
          // mode: 'payment',
          amount: newPrice,
          // currency: 'gbp'
        })

        await timer(3000); 
        const paymentComplete = red
        setRed(null)
        paymentComplete({})
      }
      updatePrice()
      
    } 
  } , [expressUpdated, red])
  

  const timer = ms => new Promise(res => setTimeout(res, ms))

  // async function load (resolvePayment) { 
  //   for (var i = 0; i < 50; i++) {
  //     console.log("XCXXCHECK INCR", i, expressUpdated, red);
  //     if (expressUpdated && expressUpdated == true) {
  //       console.log("XCXX-RESOLVING AS COMPLETE")
  //       updateGlobal({ expressUpdated: false })
  //       // setCartUpdated(false)
  //       elements.update({amount: 50000})
  //       red({
  //         // lineItems: result.updatedLineItems,
  //       })
  //     } 
  //     await timer(3000); // then the created Promise can be awaited
  //   }
  // }

  // const checkPayment = (resolvePayment) => {  
  //   // alert("CHECKING PAYMENT")
  //   console.log("XCXXCHECK PAYMENT", ccalculating, cartUpdating, expressUpdated)
  //   if (expressUpdated && expressUpdated == true) {
  //     console.log("XCXX-RESOLVING AS COMPLETE")
  //     updateGlobal({ expressUpdated: false })
  //     // setCartUpdated(false)
  //     resolvePayment({
  //       // lineItems: result.updatedLineItems,
  //     })
  //   } 
  // }

  const onShippingRateChange = async ({ resolve, shippingRate }) => {
    console.log("XCXX SHIPPING RATE CHANGED FROM APPLE PAY", shippingRate)
    setExpressShipping(shippingRate)
    console.log("XCXX SHIPPING RATE CHANGED FROM APPLE PAY", elements)
    // const response = await fetch('/calculate-and-update-amount', {
    //   data: JSON.stringify({
    //     shippingRate: shippingRate
    //   })
    // });
    // const result = await response.json();
    // setRed(() => {resolve()})
    setRed(() => () => resolve());
    // load(resolve)
    // for (let i = 1; i < 100; i++) {
    //   setTimeout(function timer() {
    //     // console.log("XCXX  SETT TIMEOUT", calculating, cartUpdating, expressUpdated)
    //     checkPayment(resolve)
    //   }, 1000);
    // }
    // while (true) {
    //   // alert("CCC")
    //   console.log("XCXX  SETT TIMEOUT")
    //   setTimeout(checkPayment(resolve),2000)      
    // }
    // if (cartUpdating == false) {
    //   alert("RESOLVED")
    //   resolve({
    //     // lineItems: result.updatedLineItems,
    //   })
    // } else {
    //   setTimeout(pollDOM, 300); // try again in 300 milliseconds
    // }
    // elements.update({amount: 50000})
    // });
  };

  const onShippingAddressChange = async ({ resolve, address }) => {
    console.log("SHIPPING CHANGED FROM APPLE PAY", address)
    // const response = await fetch('/calculate-shipping', {
    //   data: JSON.stringify({
    //     shippingAddress: address
    //   })
    // });
    // const result = await response.json();
    resolve({
      //   lineItems: result.updatedLineItems,
    });
  };

  const handleCheckout = async () => {
    // ev.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setProcessing(false);
      return;
    }
    // const billing_address = {
    //   line1: differentBillingAddress ? billingAddress.street_address : shippingAddress.street_address1,
    //   line2: differentBillingAddress ? billingAddress.street_address : shippingAddress.street_address2,
    //   city: differentBillingAddress ? billingAddress.city : shippingAddress.city,
    //   country: differentBillingAddress ? billingAddress.country_code : shippingAddress.country_code,
    //   postal_code: differentBillingAddress ? billingAddress.postal_code : shippingAddress.postal_code,
    //   state: differentBillingAddress ? billingAddress.locality : billingAddress.locality
    // }

    // const shipping_address = {
    //   line1: shippingAddress.street_address1,
    //   line2: shippingAddress.street_address2,
    //   city: shippingAddress.city,
    //   state: shippingAddress.locality,
    //   postal_code: shippingAddress.postal_code,
    //   country: shippingAddress.country_code
    // }
    const returnURL = process.env.GATSBY_RETURN_URL



    const { error, confirmPayment } = await stripe.confirmPayment({
      elements,
      clientSecret: pd.cs,
      confirmParams: {
        // shipping: {
        //   address: shipping_address,
        //   name: fullName,
        //   phone: contactNumber,
        //   carrier: "Royal Mail"
        // },
        // payment_method_data: {
        //   billing_details: {
        //     address: billing_address
        //     // postalCode: shippingAddress.postal_code
        //   }
        // },
        // redirect: 'if_required',
        return_url: returnURL,
        receipt_email: emailAddress
        // radar_options: {se ssion: radarID},
      },
    });
    if (error) {
      // alert("PAYMENT FAILED")
      console.log("ERROR WITH PAYMENT", error)
      if (error.type === "card_error" || error.type === "validation_error") {
        setError(error.message);
      } else {
        setError("An unexpected error occured.");
      }
      setProcessing(false)
    } else {
      // Never Reach Here
      // alert("GOOD PAYMENT")
      // console.log("PAYMENT GOOD", confirmPayment)
      // paymentComplete()
      // setError(null);
      // setSucceeded(true);
    }
    setProcessing(false);
    // setIsLoading(false);
    // if (payload.error) {
    //   alert("ERROR WITH PATMNENT")
    //   setError(`Payment failed ${payload.error.message}`);
    //   setProcessing(false);
    // } else {
    //   alert("PAYMENT GOOD")
    //   setError(null);
    //   setProcessing(false);
    //   setSucceeded(true);
    //   // cartDispatch({ type: 'CLEAR_CART' });
    //   navigate("/success");
    // }
  }

  return (
    <>
      <ExpressCheckoutElement
        options={expressCheckoutOptions}
        onReady={onReady}
        onClick={onClick}
        onShippingAddressChange={onShippingAddressChange}
        onShippingRateChange={onShippingRateChange}
        onConfirm={handleCheckout}
        amount={Number(ts.total_price_pence)}
      />
    </>
  )
}

export default ExpressPayment