import React, { useGlobal, setGlobal } from 'reactn'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, DrawerCloseButton } from '@chakra-ui/modal';
import { useStaticQuery, graphql } from "gatsby"
import Collapsible from 'react-collapsible';
import './drawer.css'
import { DrawerMenuItem } from './drawerMenuItem'

export function LeftDrawer() {

  const [sidebarOpen] = useGlobal('sidebarOpen')

  const {
    datoCmsSideMenu: { menuItems },
  } = useStaticQuery(graphql`
    query {
      datoCmsSideMenu {
        menuItems {
          ... on DatoCmsMenuItem {
            title
            url
            isred
            model {
              name
            }
          }
          ... on DatoCmsMenuGroup {
            subMenuItems {
              url
              title
              isred
            }
            title
            model {
              name
            }
          }
        }
      }
    }
  
  `)

  return (
    <>
      <Drawer placement={"left"} onClose={() => setGlobal({ sidebarOpen: false })} isOpen={sidebarOpen}>
        <DrawerOverlay />
        <DrawerContent backgroundColor={'black'}>
          <DrawerHeader pt={1}>
            <DrawerCloseButton _focus={{
              boxShadow: 'none'
            }}
              color={'white'} border={'none'} outline={'none'} />
          </DrawerHeader>
          <DrawerBody fontSize={18} mt={7} ml={3}>
            {menuItems.map((menuItem, i) => (
              menuItem.model.name != "Menu Item"
                ?
                  <Collapsible
                    className="menu-item" key={`subCM${i}`} trigger={menuItem.title}
                  >
                  {
                    menuItem.subMenuItems.map((subNode, i) => (
                      <DrawerMenuItem key={`dmi${i}`} item={subNode} isSubItem={true} />
                    )
                    )
                  }
                </Collapsible>
                :
                <DrawerMenuItem key={`col${i}`} item={menuItem} isSubItem={false} />
            )
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
