import { Container } from '@chakra-ui/react';
import React, {  useGlobal, useEffect } from 'reactn'


import debounceEffect from '../debounceEffect';

const CartError = () => {

  const [cartError, setCartError] = useGlobal('cartError')
  const debouncedCartError = debounceEffect(cartError, 2500)

  useEffect(() => {
    if (cartError) {
      setCartError("")
    }
  }, [debouncedCartError])

  return (
    <>
      {cartError &&
        <Container variant={'error'}>
          {cartError}
        </Container>
      }
    </>

  )
}


export default CartError