
import { API } from 'aws-amplify/'
import { navigate } from 'gatsby';
// import { emptyCart } from './rootstate';
import { cartEmpty, defaultValues } from './rootstate';

var cartState = {}
let cartStatePromise;

const post = (promise, store, path, body, options, updateAndSave) => {
    // alert("POSTING")
    let newState = { ...store, ...{ calculating: true } }

    updateAndSave(newState)
    try {
        const postData = {
            // method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            // body: JSON.stringify(body)
            body: body
        }
        // .then(res => {

        console.log("POSTING    ", path, body)
        // promise = fetch("http://localhost:3000/stage" + path, data)
        // .then((response) => response.json())
        API.post('api', path, postData)
            .then((data) => {
                console.log("DATA XXX", data)
                if (!data.os.ov) {
                    alert("INVALID ORDER")
                }
                //     console.log("DATA XXX COMPLETE")

                //     newState = {
                //         ...newState,
                //         ...{
                //             ss: {},
                //             pd: {},
                //             ts: {
                //                 pc: false,
                //             },
                //         },
                //         ...cartEmpty, ...{ calculating: false }
                //     }
                //     console.log("DATA XXX", newState)
                //     updateAndSave(newState)
                // } else {
                    if (data.ss) {
                        newState = {
                            ...newState,
                            ...{
                                cartInitialised: true,
                                ss: data.ss,
                                ts: data.ts,
                                os: data.os,
                                pd: data.pd ? data.pd : {},
                                so: data.so ? data.so : [],
                                soe: data.soe ? data.soe : [],
                                // sr: data.sr ? data.sr : {},
                                // pr: data.pr ? data.pr : {}
                            }
                        }
                        updateAndSave(newState)

                        if (!data.ss.oid || data.ss.oid == "") {
                            console.log("CLEARAING CART PRODUCTS")
                            newState = emptyCart({ ...newState, ...cartEmpty })
                        } else {
                            console.log("NOT CLEARAING CART PRODUCTS")
                        }
                    }

                    if (data.pd && data.pd.pi) {
                        console.log("DATA PR", data)
                        console.log("DATA PRG", global)
                        // const pr = global.stripe.paymentRequest({
                        //     country: data.ts.shipping_address.country_code,
                        //     currency: "gbp",
                        //     total: {
                        //         label: 'INGMARSON',
                        //         amount: Number(data.ts.total_price),
                        //     },
                        //     requestPayerName: true,
                        //     requestPayerEmail: true,
                        //     requestShipping: true,
                        //     shippingOptions: data.so
                        // })


                        // pr.canMakePayment().then(result => {
                        //     if (result) {
                        //         pr.on('paymentmethod', handlePaymentMethodReceived);
                        //         setGlobal({
                        //             paymentRequest: pr
                        //         })
                        //     }
                        // });
                        if (store.preparePayment == true || store.showPayment == true) {
                            // alert("PAYMENT")
                            newState = {
                                ...newState,
                                ...{
                                    preparePayment: false,
                                    showPayment: true,
                                    ...{ calculating: false },
                                    accordianItem: 2
                                }
                            }
                            updateAndSave(newState)
                        }

                    } else {
                        newState = {
                            ...newState,
                            ...{ calculating: false },
                            ...{
                                paymentRequest: null
                            }
                        }
                        updateAndSave(newState)
                    }
                // }
            })
            .finally(() => {
                newState = {
                    ...newState,
                    ...{ calculating: false }
                }
                updateAndSave(newState)
            }).catch((err) => {
                console.log("ERROR",)
                newState = {
                    ...newState,
                    ...{ calculating: false },
                    ...{ error: err }
                }
                updateAndSave(newState)
            })
    } catch (e) {
        // alert("ERROR")
        newState = {
            ...newState,
            ...{ calculating: false },
            ...{ error: e }
        }
        updateAndSave(newState)
    }
    // newState = {
    //     ...newState,
    //     ...{ calculating: false }
    // }

    // updateAndSave(newState)
}

const appearance = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#000',
        colorBackground: '#fff',
        fontWeight: '300',
        fontSize: '14px',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        colorIcon: '#c4f0ff',
        fontWeightLight: '300',
        fontWeightNormal: '300',
        fontWeightMedium: '300',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        spacingUnit: '3px',
        borderRadius: '0px',
    }
};



const handlePaymentMethodReceived = async (event) => {
    event.preventDefault()
    // Send the payment details to our function.
    console.log("RECEIVED EVENT", event)
    // const returnURL = "https://walpole.loca.lt/payment"
    const returnURL = process.env.GATSBY_RETURN_URL
    const paymentDetails = {
        payment_method: event.paymentMethod.id,
        shipping: {
            name: event.shippingAddress.recipient,
            phone: event.shippingAddress.phone,
            address: {
                line1: event.shippingAddress.addressLine[0],
                city: event.shippingAddress.city,
                postal_code: event.shippingAddress.postalCode,
                state: event.shippingAddress.region,
                country: event.shippingAddress.country,
            },
        },
        return_url: returnURL,
        receipt_email: event.payerEmail
    };




    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        paymentDetails,
        { handleActions: true }
    );

    if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        event.complete('fail');
    } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
                console.log("ERROR", error)
                // The payment failed -- ask your customer for a new payment method.
            } else {
                console.log("SUCCEEDED", paymentIntent)
                navigate(returnURL + "/?payment_intent=" + paymentIntent.id);
            }
        } else {
            console.log("SUCCEEDED", paymentIntent)
            navigate(returnURL + "/?payment_intent=" + paymentIntent.id);
            // The payment has succeeded.
        }
    }
}

// export function fetchShippingRates(global, force) {
//     setGlobal({ shippingError: "" })
//     if (global.rates.length == 0 || force) {
//         setGlobal({ fetchingShipping: true })
//         const shippingAddress = global.shipping_address
//         var shippingRequest = {
//             "email": global.emailAddress,
//             "shippingAddressSameAsBilling": true,
//             "discounts": [global.promoCode],
//             "items": [],
//             "billingAddressAddress1": shippingAddress.street_address1,
//             "billingAddressAddress2": shippingAddress.street_address1,
//             "billingAddressCity": shippingAddress.city,
//             "billingAddressCountry": shippingAddress.country_code,
//             "billingAddressProvince": shippingAddress.locality,
//             "billingAddressPostalCode": shippingAddress.postal_code,
//             "shippingAddressAddress1": shippingAddress.street_address1,
//             "shippingAddressAddress2": shippingAddress.street_address2,
//             "shippingAddressCity": shippingAddress.city,
//             "shippingAddressCountry": shippingAddress.country_code,
//             "shippingAddressProvince": shippingAddress.locality,
//             "shippingAddressPostalCode": shippingAddress.postal_code,
//             "subtotal": global.cartTotal.totalPrice,
//             "numberOfItemsInOrder": global.cartTotal.productQuantity,
//             "ipAddress": global.ip,
//             // "userAgent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.2 Safari/605.1.15"
//         }

//         global.cartProducts.forEach(item => {
//             shippingRequest.items.push({
//                 "price": item.price,
//                 "quantity": item.quantity,
//                 "ownGarment": item.own_garment,
//                 "kvatt": item.kvatt,
//                 "id": item.productId
//             })
//         });
//         const data = {
//             // method: "POST",
//             headers: {
//                 "Content-Type": "application/json"
//             },
//             // body: JSON.stringify(shippingRequest)
//             body: shippingRequest
//         }
//         API
//             .post('api', global, '/shippingrates', data)
//             // window
//             //   .fetch("http://localhost:3000/stage/shippingrates", data)
//             //   .then(res => {
//             //     return res.json();
//             //   })
//             .then(data => {
//                 if (!data.errors) {
//                     console.log("GOT SHIPPONG DATA", data)
//                     setShipping(data)
//                 }
//                 setGlobal({ fetchingShipping: false })
//                 // const pr = stripe.paymentRequest({
//                 //   country: data.country,
//                 //   currency: data.currency.toLowerCase(),
//                 //   total: {
//                 //     label: 'INGMARSON',
//                 //     amount: data.amount,
//                 //   },
//                 //   requestPayerName: true,
//                 //   requestPayerEmail: true,
//                 // }
//                 // )

//                 // pr.canMakePayment().then(result => {
//                 //   if (result) {
//                 //     setPaymentRequest(pr);
//                 //   }
//                 // });
//                 // setClientSecret(data.clientSecret);
//             })
//             .catch((e) => {
//                 console.log("SHIPPING ERROR", e)
//                 setGlobal({ shippingError: "FAILED TO GET SHIPPING RATES" })
//                 clearShipping()
//                 setGlobal({ fetchingShipping: true })
//                 // setFetching(false)
//             })
//         // }
//     }
// }
// export function completePaypalPayment(global, packagingData) {
//     alert("COMPLETEING PAYPAL PAYMENT - CALL COMPLETE")
//     console.log(global.orderID)
//     console.log(global.payPalOrderID)
//     if (global.orderID && global.payPalOrderID) {
//         setGlobal({ calculating: true })
//         API
//             .post('api', global, '/paypal/complete', {
//                 // fetch("http://localhost:3000/stage/paypal/complete", {
//                 // method: "POST",
//                 headers: {
//                     "Content-Type": "application/json"
//                 },
//                 body:
//                 // JSON.stringify(
//                 {
//                     order_id: global.orderID,
//                     payPalID: global.payPalOrderID
//                 }
//                 // ),
//                 // body: {order_id: data.orderID}
//             }
//             )
//             .then(res => {
//                 //     return res.json();
//                 // })
//                 // .then(res => {
//                 console.log("FINAL DETAILS DETAILS", res)

//                 if (res.orderComplete) {
//                     setGlobal({
//                         clientSecret: null,
//                         payPalOrderID: null,
//                         intentID: null,
//                         calculating: false
//                     })
//                     emptyCart()
//                     navigate("/payment?order_id=" + res.orderID);
//                 } else {
//                     alert("ERROR PAYPAL")
//                     console.log("ERROR", e)
//                     setGlobal({ calculating: false })
//                 }
//             })
//             .catch((e) => {

//             })

//     }


// }

// export function networkSetPackagingOption(packagingData) {
//     if (packagingData) {
//         setGlobal({ packagingName: packagingData.name })
//         setGlobal({
//             packaging: {
//                 calculated: true,
//                 name: packagingData.name,
//                 text: packagingData.costText,
//                 value: packagingData.cost,
//                 isCircular: packagingData.isCircular,
//                 benefit: packagingData.benefit
//             }
//         })

//     } else {
//         setGlobal({ packagingName: "" })
//         setGlobal({
//             packaging: {
//                 calculated: false,
//                 name: "",
//                 value: 0
//             }
//         })
//     }

// }
export function clearShipping() {
    updateAndSave({
        showPayment: false,
        packagingID: "",
        shippingID: "",
        so: [],
        soe: [],
        sr: {},
    })



}




export function fetchCartState(store, updateAndSave, force) {
    // SHOLD REFACTOR THIS TO MAKE THIS FASTER
    console.log("FETCHING CART STATE", store)
    const items = store.cartProducts ? store.cartProducts.map(item => (
        {
            ownGarment: item.own_garment,
            kvatt: item.kvatt,
            sku: item.sku,
            quantity: item.quantity,
        }
    )) : []
    post(cartStatePromise, store, "/checkout/cart", {
        ss: store.ss,
        ca: {
            items: items,
            promo: store.promoCode && store.promoCode.promo_code ? store.promoCode.promo_code : "",
            // Shipping Address
            sa: {
                ...store.shipping_address,
                phone: store.contactNumber,
                name: store.fullName
            },
            // Shipping & Packaging
            sp: {
                shippingID: store.shippingID,
                packagingID: store.packagingID
            },
            cd: {
                email: store.emailAddress
            }
        },
    }, null, updateAndSave)
}




// function orderData(global) {
//     // const orderData = () => {
//     console.log("CART PRODUCTS CHECKOUT", global.cartProducts)
//     const items = global.cartProducts.map(item => (
//         {
//             name: item.title,
//             ownGarment: item.own_garment,
//             kvatt: item.kvatt,
//             id: item.sku.replace('gid://shopify/ProductVariant/', ''),
//             sku: item.sku,
//             productId: item.productId.replace('gid://shopify/Product/', ''),
//             price: item.price,
//             quantity: item.quantity,
//             image: item.image.images.fallback.src,
//             currency: 'GBP',
//             product_data: {
//                 metadata: {
//                     size: item.size
//                 }
//             }
//         }
//     ))

//     console.log("GLOBAL", global)
//     var body = {
//         marketing: global.marketing,
//         items: items,
//         ipAddress: global.ip,
//         promo_code: global.promoCode && global.promoCode.validCode ? global.promoCode.promo_code : "",
//         update_intent: false,
//         shipping: global.shipping,
//         packaging: global.packaging,
//         customer: {
//             shippingAddress: global.shipping_address,
//             contactNumber: global.contactNumber,
//             fullName: global.fullName,
//             emailAddress: global.emailAddress
//         }
//     }
//     if (global.intentID) {
//         body['update_intent'] = true
//         body['intent_id'] = global.intentID
//         body['order_id'] = global.orderID
//     }
//     return body

// }