import React, { useEffect, useGlobal, useRef } from "reactn"
// import * as atatus fr?o?m 'atatus-spa';
// import { FaArrowCircle?Right } from "react-icons/fa";
import { useRollbar } from '@rollbar/react';

const Logging = () => {

    const [error] = useGlobal('error')
    const rollbar = useRollbar();
    const [ss] = useGlobal('ss')
    const [emailAddress] = useGlobal('emailAddress')
    const [fullName] = useGlobal('fullName')

    useEffect(() => {
        const logStartup = async () => {
            try {
                rollbar.info("Logging session started");
            } catch (e) {
                // Ignore Error
            }
        }
        logStartup()
    }, []);

    useEffect(() => {
        console.log("ERROR IS", error)
        const logError = async (error) => {
            try {                
                rollbar.error(error);
            } catch (e) {
                // Ignore Error
            }
        }
        if (error != undefined || error == "") {
            logError(error)
        }
    }, [error]);

    useEffect(() => {
        try {
            rollbar.configure({
                payload: {
                    person: {
                        id: 123,
                        username: fullName ? fullName : "",
                        email: emailAddress ? emailAddress : ""
                    }
                }
            });
        } catch (e) {
            // Ignore Errors
        }
    }, [emailAddress, fullName])

    
    return (
        <></>
    )
}

export default Logging
